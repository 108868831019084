import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory} from "react-router-dom";
// import { getTodo } from '../graphql/queries'
import { useAppContext } from "../libs/contextLib";
// import { deleteTodo } from '../graphql/mutations'



// import { Auth } from "aws-amplify";

import axios from 'axios';
import { animated, useSpring } from "react-spring";

  



export default function Notes() {
  const file = useRef(null);
  const { id } = useParams();
  const { isAuthenticated } = useAppContext();

  const [note, setNote] = useState([]);
  const [note_date, setDate] = useState([])
  const [content, setContent] = useState("");
  const history = useHistory()
  const fade  = useSpring({
    from:{

      transform: `translate3d(-100%,0,0)`
    },
  
   
      transform: `translate3d(0,0,0)`

  
  })

  useEffect( async () => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
    }

    try {
      await loadNote();
    } catch (e) {
      console.log(e);
    }

    //push back
    history.listen(location => {
      if (history.action === 'POP') {
        alert('pushed')
      }
    })


    onLoad();
  },
  

  
   [id]);


  
  function goHome(){
    history.push('/')
  }
  async function deleteNote(event){
    event.preventDefault()


    const user = 6
    const userId = user.username







  axios({
        method: 'post',
        url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
       
        withCredentials: false,
        params: {
          userId: userId,
          noteId: id,
          path:'delete'
        },
      }).then(function (response) {
        console.log(response);
        let note_info = response.data
        console.log(note_info)
        setNote(note_info[0])
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  

   


async function loadNote() {
      const user = 5
      const userId = user.username
      console.log(userId)
      console.log(id)
      axios({
        method: 'post',
        url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
       
        withCredentials: false,
        params: {
          userId: userId,
          noteId: id,
          path:'note'
        },
      }).then(function (response) {
        console.log(response);
        let note_info = response.data
        console.log(note_info)
        setNote(note_info[0])
      })
      .catch(function (error) {
        console.log(error);
      });
      


      }
  

    return (

      <animated.div style={fade}class="flex flex-col ">

        <div class=" items-center h-1/2"  >


            <div class="h-1/4">
              <div class="flex bg-green-200 h-1/3 justify-end items-center " >
                <div class="m-7">
                time logged: {note.tod}

                </div>
              </div>
              <div class="flex bg-green-400 h-2/3 justify-center items-center">
                <p>{note.name}</p>
              </div>
            </div>

            <div class="h-1/4">
              <div class="flex bg-green-400 h-2/3 justify-center items-center overflow-x-scroll" >
                <div class=" w-3/5" >
              <p class="overflow-scroll">{note.description}</p> 
                </div>
              </div>
              <div class="flex bg-red-400 h-1/3 justify-end items-center">
                <div class="m-8">
                  <button onClick={deleteNote}
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    Delete
                  </button>
              </div>
              </div>
            </div>
    
        </div>
    </animated.div>
    );
}