import { BrowserRouter, NavLink } from "react-router-dom";
import { AppContext } from "./libs/contextLib";
import {  LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import Home from "./containers/Home";
import React, { useState , useEffect} from "react";
import Routes from "./Routes";
import "./App.css";
import { AwsConfigAuth } from "./config/auth";
import NewNav from './containers/NewNav/NewNav';





function App() {
  const history = useHistory()

  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true);

async function onLoad() {
  try {
    // await Auth.currentSession();
    userHasAuthenticated(true);
  }
  catch(e) {
    if (e !== 'No current user') {
      alert(e);
    }
  }

  setIsAuthenticating(false);
}


async function handleLogout(event){
  event.preventDefault();

try {
  //  await Auth.signOut()
  //  .then(() => {
  //   console.log('Sign out complete')
  //   console.log('auth3')
  //  })
} catch (error) {
  console.log(error)
}
  userHasAuthenticated(false)
}
 
async function handleCreate(){
  history.push("/create-note")
}

return (

  <div className="App container">
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }} >
      <Routes />
    </AppContext.Provider>

  </div>
);
}


export default App;

