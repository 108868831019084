import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
// import { listTodos } from '../graphql/queries'
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import "./Home.css";
import { Navbar, Nav, NavDropdown, FormControl, Form, Button } from 'react-bootstrap';
// import * as  queries  from "../graphql/queries";


export default function Home() {
  const [notes, setNotes] = useState([]);
  
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (dater) => {
    const date = new Date(dater);

    // Options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long', // 'short' for abbreviated month names
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short' // Optional: for including timezone
    };
    
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
  };

useEffect(
 async () => {
   
  async function onLoad() {
    await loadNotes()
    if (!isAuthenticated) {
      return;
    }
 
    
  checkState()

  }

   onLoad();
},[isAuthenticated]);

async function loadNotes () {
// const user = await Auth.currentUserInfo();
const user = 5
const userId = user.username

await axios({
  method: 'post',
  url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
 
  withCredentials: false,
  params: {
    userId: userId,
    path:'list'
  },
}).then(function (response) {
  console.log(response.data);
  let note_list = response.data

  note_list = note_list.map(c => { 
  let dater = c.tod
  const date = new Date(dater);

  // Options for formatting the date
  const isDST = date.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour12: false }).endsWith('EDT');

  // Adjust the date to EST if it's in DST
  if (isDST) {
    date.setHours(date.getHours() - 1);
  }
  
  // Options for formatting the date
  const options = {
    year: 'numeric',
    month: 'long', // 'short' for abbreviated month names
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  };
  
  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', options);
return { ...c, tod: formattedDate };
  })
  console.log('notesd', note_list)
  setNotes(note_list)
})
.catch(function (error) {
  console.log(error);
});











console.log('userid', userId)
await axios.get('https://18.117.186.91/mypath',  { params: { userId: userId, path:'list' } }
) .then(function (response) {
  console.log('new post')

  console.log(response)
  console.log('^^ http response')
  let note_list = response.data
  console.log(note_list)
  setNotes(note_list)
})
.catch(function (error) {
  console.log(error);
});
console.log('worked adding')
console.log(notes)

// let logs =  allTodos.data.listTodos.items
// console.log(logs)

// console.log('ss')
// setNotes(logs)
}



  function renderLander() {

    return (
      <div>
      <div className="lander">
        <h1>Pain Scout </h1>
        <p>A simple way for patients to advocate for the pain they feel outside the doctors office</p>
        
        <p> Ask informed questions that directly relate to they pain you feel </p>
      </div>
     
      </div>
    );
  }
function checkState(){


  let currentState = 0

  if (currentState.length < 1){
    return baseRender()
  } 
  else {
    // return <NoteList notes={notes} />
    return(<div>
      <h1> note list will live</h1>
    </div>)
  }
}

function baseRender(){
  console.log(notes)
  console.log('aa')
  return(
    <div> 
      <h1> Thank you for using Doctorly</h1>
    </div>
  )
}

function entries_load(){
console.log(notes)

  let new_l = notes.map((c) => {
    return (
    <div class="flex flex-col bg-blue-400 h-96 m-9 w-4/5 md:w-1/4">

      <div class="h-1/2">
        <div class="flex bg-green-200 h-1/3 justify-end items-center " >
 
          <div class="m-7">
         time logged: {c.tod}
          </div>
        </div>
        <div class="flex bg-green-300 h-2/3 justify-center items-center text-center">
           <p>{c.part}</p>
        </div>
      </div>

      <div class="h-1/2">
        <div class="flex bg-green-400 h-2/3 justify-center items-center text-center" >
          <div class=" w-3/5" >
         <p class="">{c.description}</p> 
          </div>
        </div>
        <div class="flex bg-purple-400 h-1/3 justify-end items-center">
          <div class="m-8">
          <Link to={`/notes/${c.noteid}`} class=""><em>details</em></Link>
        </div>
        </div>
      </div>

 
    </div>
  )})

  
  return (
  <div class="flex container flex-col min-w-full justify-between  items-center">{new_l}</div>
    )
}




  return (
<div className="Home">
      { isAuthenticated ? checkState(): renderLander()}
    </div>
  );
}



