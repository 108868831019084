import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
// import { createTodo } from '../graphql/mutations';
import { Alert } from "react-bootstrap";

import axios from 'axios';
// axios.defaults.baseURL = '3.135.186.254:80';
import './NewNote.css'



export default  function Notes() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [note, setNote] = useState(null);
  const [display_index, setdisplay_index] = useState(0)
  const [content, setContent] = useState("");
  const [part, setPart] = useState([]);


  const formatDate = (date) => {

    return date.toLocaleString({timeZone:"America/New_York"});
  };
  //Give time it was created
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  

  //letters added/subtracted as user writes
  function handleTermChange(event){
    setContent({content: event.target.value})
  }

  // part is changed as person presses different body part buttons 
  function handlePartChange(event){
    partUndater(event)

  }

  function partUndater(e){
    let val = e.target.innerHTML
    let parts  = part



    if( parts.length == 0 ){
      parts.push(val)
      setPart(parts)
      e.target.style.backgroundColor = "Blue"
      e.target.style.color = "white"
    }else{
    
    
    
    if(!parts.includes(val)){
      e.target.style.backgroundColor = "Blue"
      e.target.style.color = "white"
      parts.push(val)
      setPart(parts)
      return
    }
    
    
    
      let p_len = parts.length
        for(let i = 0; i < p_len; i++){
          if(parts[i] == val ){
            e.target.style.backgroundColor = "white"
            e.target.style.color = "black"
            let slot = parts[i]
            let partTwo = parts.filter(function(item) {
                return item !== slot })
              setPart(partTwo)
              console.log(part)
                break;
            }
          }
    
    }
  }


function updateIndex(){
  let new_display = display_index +1
  setdisplay_index(new_display)
}

  //Send data to cloud
  async function  logData (){
    const user =6
    const userId = user.username
    const d = new Date();
    let contentShell = content
    let partShell = part

    // gathering all the necessary pieces for item put in db
    let noteId = uuidv4();
    let day = d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear()
    let partString = part.join()
    let painDescription = contentShell.content 
    let timeCheck =  new Date();

    let param_one  =  {
      userId: userId,
      noteId: noteId,
      part: partString,
      description: painDescription, 
      tod: timeCheck
    }

  const headers = {     
       'Access-Control-Allow-Origin': '*', // Add any other headers as needed
  
    }
    
    //passing all the datapoints into createTodo schema
    // let text = "INSERT INTO noteinfo (id, part, description, tod) VALUES ($1, $2, $3, $4);"
    // let values = [ userId, noteId, partString, painDescription, timeCheck]



  //sending data using CreateTodo mutation with 3data in schema  
    try {
      console.log(userId)
      console.log(noteId)
      console.log(partString)
      console.log(painDescription)
      console.log(timeCheck)



      axios({
        method: 'post',
        url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
       
        withCredentials: false,
        params: {
          userId: userId,
          noteId: noteId,
          part: partString,
          description: painDescription, 
          tod: timeCheck,
          path:'add'
        },
      }).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      
     
    //  await axios.post('https://r7ug1vyrid.execute-api.us-east-2.amazonaws.com/dev/mypath', {
    //     userId: userId,
    //     noteId: noteId,
    //     part: partString,
    //     description: painDescription, 
    //     tod: timeCheck
    //   }, {headers:headers}
    //   )
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
      
      
      // history.push('/')

    } catch (error) {
     console.log(error)
    }
  
  }



  // check for mistakes then start sending process to cloud
  function sendData(e){
    e.preventDefault();

    if (typeof content.content == 'undefined') {
      alert('please type in the bar')
    }else{
   logData()
   let newc = display_index + 1
setdisplay_index(newc)
    }


  }

  function displayHolder(){
    const display_slider = [ display1(),display2(),display3()]
    return display_slider[display_index]
  }
  

  function display1(){
    return (
      <div className="display1">
  
  <div class="btn-group" role="group" aria-label="...">
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Head</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Shoulders</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Chest</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Back</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Stomach</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Waist</button> 
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Hands</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Wrists</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Hips</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Thighs</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Feet</button>
  
  
  
  
  </div>
  
  
  
        <div class="input-group">
           <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon2"  onChange= {handleTermChange}/>
        </div>
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn btn-default"   onClick= {sendData}  > Submit  </button>
        </div>
       
      </div>
    );
  }

  function display2(){
    return(
    <div className="pain_box">
      <div className="pain_levels">
   
          <button className="box_pain">0</button>
          <button className="box_pain">1</button>
          <button className="box_pain">2</button>
          <button className="box_pain">3</button>
          <button className="box_pain">4</button>
          <button className="box_pain">5</button>
          <button className="box_pain">6</button>
          <button className="box_pain">7</button>
          <button className="box_pain">8</button>
          <button className="box_pain">9</button>
      </div>
     <button onClick={updateIndex}> next step </button>
    </div>
    )
  }

  function display3(){
    return(
      <div>
        <h1>
          it works. Screen 3
        </h1>
      </div>
    )
  }

 return(
  <section className="note_holder"> 
   { displayHolder()}
  </section>
    
 )
}
